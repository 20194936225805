import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/solid";
import { useParams, useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Risk } from "components/Risk";
import { useDebounceCallback } from "utils/hooks";
import { useUpdateProject } from "hooks/useProjects";
import { BasePage } from "components/BasePage/BasePage";
import { Risk as RiskType } from "components/Risk/types";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { CreateRiskOnDashboard } from "components/Risk/CreateRiskOnDashboard";
import { PrimaryButton } from "components/Button/PrimaryButton";

interface RiskRouteParams {
  projectId: string;
}

export const Risks = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<RiskRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);
  const { updateProject } = useUpdateProject(
    project!,
    t("steps.risks.edit.resorting_success")
  );
  const [items, setItems] = useState<RiskType[]>(project?.risks || []);

  const handleUpdate = useDebounceCallback((newItems: RiskType[]) => {
    project!.risks = newItems;

    updateProject(projectId);
  }, 2000);

  const onDragEnd = (result: any) => {
    if (!result.destination) return; // dropped outside the list

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
    handleUpdate(reorderedItems);
  };

  useEffect(() => {
    setItems(project?.risks || []);
  }, [project]);

  const risks = project?.risks;

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.risks.label",
          location: `/projects/${projectId}/risks`,
        },
      ]}
      loading={fetchInProgress}
    >
      <div>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-3xl leading-6 font-medium text-gray-900">
            {t("steps.risks.label")}
          </h3>
          <div>
            <CreateRiskOnDashboard
              projectCRN={projectId}
              button={(props) => (
                <button
                  onClick={props.onClick}
                  type="button"
                  className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
                >
                  <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("risks.create_new")}
                </button>
              )}
            />
          </div>
        </div>

        <div>
          {(risks?.length && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                      <Draggable
                        key={item.resource_id}
                        draggableId={item.resource_id.toString()}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 mr-2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                />
                              </svg>

                              <Risk
                                key={item.resource_id}
                                risk={item}
                                projectId={projectId}
                                idx={index}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )) || <EmptyRisks projectId={projectId} />}
        </div>
      </div>

      <div className="pt-9 text-right flex justify-center items-center float-right">
        <a
          className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={() => history.push(`/projects/${projectId}`)}
        >
          {t("risks.back_to_overview")}
        </a>

        <PrimaryButton onClick={() => history.push(`/projects/${projectId}`)}>
          {t("risks.save_all")}
        </PrimaryButton>
      </div>
    </BasePage>
  );
};

interface EmptyRiskProps {
  projectId: string;
}

const EmptyRisks = ({ projectId }: EmptyRiskProps) => {
  const { t } = useTranslation();

  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t("risks.no_risks")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {t("risks.create_new_subline")}
      </p>
      <div className="mt-6">
        <CreateRiskOnDashboard
          projectCRN={projectId}
          button={(props) => (
            <button
              onClick={props.onClick}
              type="button"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {t("risks.create_new")}
            </button>
          )}
        />
      </div>
    </div>
  );
};

export default Risks;
