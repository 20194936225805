import React from "react";
import { useClient } from "utils/client";
import { useHistory } from "react-router";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { Risk } from "../types";
import { Project } from "types";
import { FormCb } from "../formdata";
import { ControlContext } from "context/Controls";
import { TrackingContext } from "context/Tracking";
import { FeedbackContext } from "context/Feedback";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";

const updateRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: FormCb,
  riskId: string,
  project: Project,
  projectId: string
) => {
  try {
    // @ts-ignore
    project.risks = project.risks.map((oldRisk: Risk) => {
      if (oldRisk.resource_id !== riskId) {
        return oldRisk;
      }

      return {
        ...risk,
        resource_id: riskId,
        mitigations,
        assessment: {
          impact: risk.impact,
          people_count: risk.people_count,
          duration: risk.duration,
          probability: risk.probability,
          detectability: risk.detectability,
        },
      };
    });

    await client.put(`/projects/${projectId}`, { payload: project });
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }

  return risk;
};

export const useUpdateRisk = (
  projectId: string,
  risk: Risk,
  project?: Project
) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { track } = React.useContext(TrackingContext);
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate } = useMutation(
    async (v: FormCb) =>
      await updateRisk(client, v, risk.resource_id, project!, projectId),
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: (newRisk) => {
        queryClient.refetchQueries(["risks", projectId]);
        queryClient.refetchQueries(["projects", projectId]);

        history.push(`/projects/${projectId}/risks?saved=true`);

        track("update risk");
        displayFeedback!(FeedbackLocation.Risk);
        displayNotification!(
          "Risiko geupdated!",
          `Risiko "${newRisk.description}" wurde bearbeitet`
        );
      },
    }
  );

  return mutate;
};

const deleteRisk = async (
  client: AxiosInstance,
  projectId: string,
  riskId: string,
  project: Project
) => {
  project.risks = project.risks.filter(
    ({ resource_id }: any) => resource_id !== riskId
  );

  await client.put(`/projects/${projectId}`, { payload: project });
};

export const useDeleteRisk = (projectId: string, riskId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { project } = useProjectDetails(projectId);
  const { track } = React.useContext(TrackingContext);
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate } = useMutation(
    async () => await deleteRisk(client, projectId, riskId, project!),
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: () => {
        queryClient.refetchQueries(["risks", projectId]);
        queryClient.refetchQueries(["projects", projectId]);

        history.push(`/projects/${projectId}/risks?saved=true`);

        track("delete risk");
        displayFeedback!(FeedbackLocation.Risk);
        displayNotification!("Risiko gelöscht!", "");
      },
    }
  );

  return mutate;
};
