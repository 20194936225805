import { CheckCircleIcon } from "@heroicons/react/solid";

export const MagicLinkSent = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex items-center text-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-md font-medium text-green-800">
            Sehen Sie in Ihren E-Mails nach!
          </p>
          <p className="text-xs text-green-800">
            Der Magic-Link ist unterwegs zu Ihnen. Sie können dieses Fenster nun
            schließen.
          </p>
        </div>
      </div>
    </div>
  );
};
