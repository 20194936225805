import React from "react";
import { PlusIcon } from "@heroicons/react/outline";

import { BasePage } from "components/BasePage/BasePage";
import {
  ManufacturerWithDocuments,
  useManufacturers,
} from "components/Manufacturers/hooks/useManufacturers";
import { CreateManufacturerOnDashboard } from "components/Manufacturers/CreateManufacturerOnDashboard";
import { useTranslation } from "react-i18next";
import { SortableTable } from "components/SortableProjectsTable";
import { ManufacturerRow } from "components/Manufacturers/Row";
import { Sorting, ManufacturerColumn, ColumnSortConfig } from "types";
import { sorting } from "utils/sorting";

export const Manufacturers = () => {
  const { t } = useTranslation();
  const { manufacturers, fetchInProgress } = useManufacturers();

  const columnSortMap: Record<
    ManufacturerColumn,
    ColumnSortConfig<ManufacturerWithDocuments>
  > = {
    [ManufacturerColumn.manufacturer]: {
      accessor: (manufacturer) => manufacturer.company,
    },
    [ManufacturerColumn.city]: {
      accessor: (manufacturer) => manufacturer.city,
    },
  };

  const getSortedData = (
    sortOrder: string | null,
    sortColumn: string | null
  ) => {
    if (sortColumn == null || sortOrder == null) {
      return manufacturers || [];
    }
    return (
      manufacturers?.slice().sort((a, b) => {
        return sorting(
          a,
          b,
          sortOrder as Sorting,
          sortColumn as ManufacturerColumn,
          columnSortMap
        );
      }) || []
    );
  };

  return (
    <BasePage
      loading={fetchInProgress}
      breadcrumbItems={[
        { label: "Dashboard", location: "/manufacturers" },
        { label: "form.manufacturer.list", location: "/manufacturers" },
      ]}
    >
      <main className="flex-1 relative z-0 focus:outline-none" tabIndex={0}>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              {t("form.manufacturer.list")}
            </h1>
          </div>
          {manufacturers?.length && (
            <div className="flex flex-row gap-1">
              <CreateManufacturerOnDashboard
                button={(props) => (
                  <button
                    onClick={props.onClick}
                    type="button"
                    className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3"
                  >
                    {t("form.manufacturer.create_new")}
                  </button>
                )}
              />
            </div>
          )}
        </div>

        {manufacturers?.length && (
          <SortableTable
            data={manufacturers}
            columns={[
              {
                label: t("dashboard.table.manufacturer"),
                name: ManufacturerColumn.manufacturer,
                sortable: true,
                start: true,
              },
              {
                label: t("dashboard.table.city"),
                name: ManufacturerColumn.city,
                sortable: true,
              },
            ]}
            sort={getSortedData}
            renderRow={(manufacturer) => (
              <ManufacturerRow key={manufacturer.crn} {...manufacturer} />
            )}
          />
        )}
        {!manufacturers?.length && <EmptyDashboard />}
      </main>
    </BasePage>
  );
};

const EmptyDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Keine Hersteller in Ihrer Datenbank
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Legen Sie einen neuen Hersteller an.
      </p>
      <div className="mt-6">
        <CreateManufacturerOnDashboard
          button={(props) => (
            <button
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {t("form.manufacturer.create_new")}
            </button>
          )}
        />
      </div>
    </div>
  );
};

export default Manufacturers;
