import { useParams } from "react-router-dom";

import { useOneProject } from "hooks/useProjects";
import { BasePage } from "components/BasePage/BasePage";
import { WysiwygInput } from "components/inputs/WysiwygInput";

interface DetailsRouteParams {
  projectId: string;
}

export const CreateManual = () => {
  // const { projectId } = useParams<DetailsRouteParams>();
  // const project = useOneProject(projectId);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        // {
        //   label: `${project?.projectdata?.product_name || projectId}`,
        //   location: `/projects/${projectId}`,
        // },
        // { label: "Details", location: `/projects/${projectId}` },
      ]}
    >
      <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
        <div className="2xl:block min-w-0 flex-1">
          <span className="text-3xl pr-2">Betriebsanleitung</span>
        </div>
      </div>
      <div>
        {/* <WysiwygInput onBlur={(content) => console.log(content)} /> */}
      </div>
    </BasePage>
  );
};
