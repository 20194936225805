import React from "react";
import { Link } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/outline";

import { AnnexSection } from "./types";
import { useAnnexSections } from "./hooks/useAnnexSections";
import { ListSearch } from "components/ListSearch/ListSearch";

interface AnnexSelectorProps {
  onChange: (ids: AnnexSection[]) => void;
  sections?: AnnexSection[];
}

export const AnnexSectionSelector = (props: AnnexSelectorProps) => {
  const { sections = [] } = props;
  const { annexSections } = useAnnexSections();

  return (
    <>
      <h3 className="text-lg">Gewählte Anhangfragen</h3>

      <ListSearch
        placeholder="Anhangfragen durchsuchen. Z.B.: '1.1.7' oder 'Unkontrollierte Bewegung'"
        values={annexSections?.map((s) => ({
          name: s.name,
          content: s.questions && s.questions[0].content,
        }))}
        translatedValues={annexSections?.map((s) => ({
          name: s.name,
          content: s.questions && s.questions[0].content,
        }))}
        onSelect={(selectedAnnex) => {
          const as = [
            ...sections,
            annexSections?.find((a) => a.name === selectedAnnex.name)!,
          ];
          props.onChange(as);
        }}
      />

      <div className="grid grid-cols-5 gap-4">
        {sections.map((f) => (
          <div
            className="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center justify-center space-x-3"
            key={f.resource_id}
          >
            <Link
              className="hover:text-cyan-700"
              to={{ pathname: `/_backoffice/_annex/${f.resource_id}/edit` }}
            >
              [{f.name}] {f?.questions[0].content}
            </Link>
            <TrashIcon
              className="flex-shrink-0 h-4 text-red-500 hover:text-black cursor-pointer"
              onClick={() => {
                const filteredAnnex = sections.filter(
                  (a) => a.resource_id !== f.resource_id
                );

                props.onChange(filteredAnnex);
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};
