import _axios from "axios";
import Select from "react-select";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { classNames } from "utils/classNames";

export interface PiktogramSelectProps {
  id: string;
  info?: string;
  label: string;
  value?: any;
  defaultValue?: any;
  required?: boolean;
  onBlur?: (e: any) => void;
}

const styles = {
  control: (css: any) => ({
    ...css,
    borderColor: "none",
    borderWidth: "none",
    borderRadius: "none",
  }),
  option: (css: any) => ({
    ...css,
    backgroundColor: "none",
    textColor: "none",
  }),
  input: (css: any) => ({
    ...css,
    input: {
      outline: "none",
    },
    "*:focus": {
      boxShadow: "none",
    },
  }),
};

export const Piktograms = (props: PiktogramSelectProps) => {
  const { t } = useTranslation();

  const { data: piktos, isLoading } = useQuery(["piktograms"], async () => {
    const d = await _axios.get(
      process.env.REACT_APP_TECH_CONTENT_URL + "/piktograms/piktograms.json"
    );

    return d.data;
  });

  if (isLoading) return null;

  return (
    <div className="relative inline-block text-left col-span-6">
      {t(props.label)}
      {!props.required && (
        <span id="phone-optional" className="text-sm text-gray-400 float-right">
          optional
        </span>
      )}

      <div>
        <Select
          isClearable
          className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 sm:text-sm"
          styles={styles}
          classNames={{
            control: ({ isFocused }) =>
              `border border-gray-300 rounded-md ${
                isFocused ? "border-cyan-500" : ""
              }`,
            option: ({ isFocused, isSelected }) =>
              classNames(
                isFocused ? "bg-cyan-500" : "",
                isSelected ? "bg-cyan-400" : ""
              ),
          }}
          defaultValue={{
            value: props.defaultValue,
            label: props.defaultValue ? (
              <>
                <img
                  src={
                    process.env.REACT_APP_TECH_CONTENT_URL +
                    "/piktograms/svgs/" +
                    props.defaultValue +
                    ".svg"
                  }
                  className="h-6 inline mr-2"
                  alt=""
                />
                {props.defaultValue}
              </>
            ) : (
              t("form.basic.no_pictogram")
            ),
          }}
          onChange={(value) => {
            // @ts-ignore
            props.onBlur!({ target: { value: value?.value } });
          }}
          options={piktos?.map((pikto: any) => ({
            value: pikto.label,
            label: (
              <>
                <img
                  src={
                    process.env.REACT_APP_TECH_CONTENT_URL +
                    "/piktograms/svgs/" +
                    pikto.label +
                    ".svg"
                  }
                  className="h-6 inline mr-2"
                  alt=""
                />
                {pikto.label}
              </>
            ),
          }))}
        />
      </div>
    </div>
  );
};
