import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Project, Steps } from "types";
import {
  possibleSteps,
  fallbackRequiredSteps,
  getRequiredStepsFromDirective,
} from "components/Progress/Steps";
import { Feature, useFeatureFlags } from "context/FeatureFlag";

export const useSteps = (project: Project | undefined) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  const { steps, allStepsDone } = useMemo(() => {
    if (!project) return { steps: [], allStepsDone: false };

    const projectId = project?.projectdata?.crn;
    let requiredSteps: Steps[] =
      fallbackRequiredSteps[project.projectdata.category];

    if (isFeatureEnabled(Feature.MultiDirectiveSearch)) {
      requiredSteps = getRequiredStepsFromDirective(
        project.directives.map((d) => d.name),
        project.projectdata.category
      );
    }

    const steps = requiredSteps
      ?.filter((stepKey) => possibleSteps[stepKey])
      .map((stepKey) => {
        const stepDefition = possibleSteps[stepKey];

        return {
          ...stepDefition,
          key: stepKey,
          label: t(stepDefition.label),
          subline: t(stepDefition.subline),
          complete:
            stepDefition.completeFn(project).complete ||
            Boolean(stepDefition.isPreview),
          location: stepDefition.location(projectId, project),
        };
      });

    return { steps, allStepsDone: steps?.every(({ complete }) => complete) };
  }, [project, t, isFeatureEnabled]);

  return { steps, allStepsDone };
};
