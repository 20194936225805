import { AxiosInstance } from "axios";
import { ControlContext } from "context/Controls";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Project } from "types";
import { useClient } from "utils/client";

const fetchProjects = async (client: AxiosInstance) => {
  const { data } = await client.get("/projects");
  return data;
};

export const useProjects = () => {
  const client = useClient();
  const { displayNotification } = useContext(ControlContext);

  const { data: projects, isLoading } = useQuery<Project[]>(
    ["projects"],
    () => fetchProjects(client),
    {
      onError: (e) => {
        displayNotification!(
          "Projekte konnten nicht geladen werden. Seite bitte manuell neuladen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
    }
  );

  return { projects: projects || [], isLoading };
};

export const useOneProject = (projectCrn: string): Project | undefined => {
  const projectPayload = useProjects();

  const project = projectPayload.projects?.length
    ? projectPayload.projects.find((p) => p.projectdata.crn === projectCrn)
    : undefined;

  return project;
};

export const useDeleteProject = () => {
  const axios = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    (projectId: string) => axios.delete(`/projects/${projectId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["projects"]);

        displayNotification!("Projekt erfolgreich gelöscht", "", "success");
      },
    }
  );

  return { deleteProject: mutate };
};

export const useUpdateProject = (project: Project, successMessage?: string) => {
  const axios = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    (projectId: string) =>
      axios.put(`/projects/${projectId}`, { payload: project }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["projects", project.projectdata.id]);
        successMessage && displayNotification!(successMessage, "", "success");
      },
      onError: (e) => {
        displayNotification!(
          "Projekt konnte nicht vollständig aktualisiert werden. Eingaben überprüfen und erneut versuchen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
    }
  );

  return { updateProject: mutate };
};
