import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/solid";

import Toggle from "components/Toggle/Toggle";
import { useProjects } from "hooks/useProjects";
import { BasePage } from "components/BasePage/BasePage";
import { SortableTable } from "components/SortableProjectsTable";
import { CreateProjectOnDashboard } from "components/Dashboard/CreateProjectOnDashboard";
import { ProjectRow } from "components/ProjectsTable/Row";
import {
  Project,
  Status,
  Sorting,
  ProjectColumn,
  ColumnSortConfig,
} from "types";
import { sorting } from "utils/sorting";

export const Dashboard = () => {
  const { projects: data, isLoading } = useProjects();

  const [showArchived, setShowArchived] = useState(false);
  const { t } = useTranslation();

  const getStatusAccessor = (project: Project): string => {
    return project.projectdata.status === Status.wip &&
      project.projectdata.version > 0
      ? t("dashboard.table.status.done")
      : t("dashboard.table.status.wip");
  };

  const columnSortMap: Record<ProjectColumn, ColumnSortConfig<Project>> = {
    [ProjectColumn.project]: {
      accessor: (project) => project.projectdata.product_name,
    },
    [ProjectColumn.manufacturer]: {
      accessor: (project) => project.manufacturer.company,
    },
    [ProjectColumn.category]: {
      accessor: (project) => project.projectdata.category,
    },
    [ProjectColumn.status]: {
      accessor: (project) => getStatusAccessor(project),
    },
    [ProjectColumn.last_update]: {
      accessor: (project) => project.lastUpdated,
    },
  };

  const getSortedData = (
    sortOrder: string | null,
    sortColumn: string | null
  ) => {
    let filteredData =
      data?.filter(
        (project) => project.projectdata.status === Status.wip || showArchived
      ) || [];

    if (sortColumn == null || sortOrder == null) {
      return filteredData;
    }

    return filteredData.slice().sort((a, b) => {
      return sorting(
        a,
        b,
        sortOrder as Sorting,
        sortColumn as ProjectColumn,
        columnSortMap
      );
    });
  };

  return (
    <BasePage
      loading={isLoading}
      breadcrumbItems={[
        { label: "Dashboard", location: "/dashboard" },
        { label: "projects", location: "/dashboard" },
      ]}
    >
      <main className="flex-1 relative z-0 focus:outline-none" tabIndex={0}>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              {t("dashboard.header")}
            </h1>
          </div>
          {data?.length > 0 && (
            <div className="flex flex-row gap-1">
              <div className="w-fit pt-6 hidden sm:block">
                <Toggle
                  rightText={t("dashboard.show_archived")}
                  checked={showArchived}
                  onToggle={() => setShowArchived(!showArchived)}
                />
              </div>
              <CreateProjectOnDashboard
                button={(props) => (
                  <button
                    onClick={props.onClick}
                    type="button"
                    className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3"
                  >
                    {t("dashboard.create_project")}
                  </button>
                )}
              />
            </div>
          )}
        </div>
        {data?.length > 0 && (
          <SortableTable
            data={data?.filter(
              (project) =>
                project.projectdata.status === Status.wip || showArchived
            )}
            columns={[
              {
                label: t("dashboard.table.project"),
                name: ProjectColumn.project,
                sortable: true,
                start: true,
              },
              {
                label: t("dashboard.table.manufacturer"),
                name: ProjectColumn.manufacturer,
                sortable: true,
              },
              {
                label: t("dashboard.table.category"),
                name: ProjectColumn.category,
                sortable: true,
              },
              {
                label: t("dashboard.table.status.status"),
                name: ProjectColumn.status,
                sortable: true,
              },
              {
                label: t("dashboard.table.last_edit"),
                name: ProjectColumn.last_update,
                sortable: true,
              },
            ]}
            sort={getSortedData}
            renderRow={(project, index) => (
              <ProjectRow key={project.projectdata.crn} {...project} />
            )}
          />
        )}
        {!data?.length && <EmptyDashboard />}
      </main>
    </BasePage>
  );
};

const EmptyDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t("dashboard.no_products")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {t("dashboard.create_project_subline")}
      </p>
      <div className="mt-6">
        <CreateProjectOnDashboard
          button={(props) => (
            <button
              data-test="empty-create-project-"
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {t("createNewProject")}
            </button>
          )}
        />
      </div>
    </div>
  );
};
